/**
 * # Litium Dynamic Product Listing
 * 
 * This composable is used to create a dynamic product listing based on
 * Distancify.LitiumAddOns.ElasticSearch. It has support for handling facets, but more importantly
 * it allows child blocks to receive a part of a listing's products in order for users to create
 * dynamic layouts which incorporates the listing itself, creating a seamless integration
 * between editorial content and the listing products.
 * 
 * ## Using the module
 * The root page component should execute the default export of this module in it's setup()
 * function. This will set up the necessary data fetching from cache and provide functions
 * to child blocks for accessing the state.
 * 
 * Child components can then use the getContext() function to inject the state and functions
 * they need.
 * 
 * ## Working with Facets
 * The architecture for facets it's based on the idea that getContext() provides access to
 * the available facets, as well as the selected facets. This data is read-only.
 * 
 * There are two ways to apply a new facet. You can either use drapeJS native navigate()
 * function. Or you can call the toggleFacetValue(facet, value) function provided by
 * getContext() function.
 * 
 * The benefit of getContext() is that it will update the current state immediately, so the
 * user will not have to wait for a round-trip before selecting another facet value, or risking
 * overriding the previous selection.
 */

import { reactive, inject, ref, provide, InjectionKey, onMounted, watch, Ref, readonly, computed } from 'vue';
import { routeKey, subscribeKey, unsubscribeKey, navigateKey, Route, bind } from '@drapejs/core';
//import { useGA4 } from '@/composables/gtm';

import useContext from "./useContext";

const sortOptions: string[] = [
  'popularity',
  'nameasc',
  'namedesc',
  'priceasc',
  'pricedesc',
];
export interface CategoryBlockOptions {
  setProducts(products: [any]): void
  numProducts: number
}

export interface FacetHit {
  id: string
  name: string
  type: string
  isActive: boolean
  values: FacetValue[]
  range: {
    min: number
    max: number
  }
  selectedRange: {
    min: number
    max: number
  }
}

export interface FacetValue {
  id: string
  text: string
  count: number
  isActive: boolean
}

export interface FacetRangeValue {
  min: number
  max: number
}

export interface ListingData {
  facets: FacetHit[]
  hits: number
  activeFacets: {
    id: string
    type: string
    values: {
      id: string
      text: string
    }[]
  }[]
  products: any[]
  searchPhrase: string
}

export interface ActiveFacet {
  id: string
  name: string
  type?: string
  values: {
    value: string
    text: string
  }[]
}

export const registerListingBlockKey: InjectionKey<(uuid: string, options: CategoryBlockOptions) => void> = Symbol('registerCategoryBlockKey');
export const unregisterListingBlockKey: InjectionKey<(uuid: string) => void> = Symbol('unregisterCategoryBlockKey');
export const allFacetsKey: InjectionKey<Ref<FacetHit[]>> = Symbol("allFacets");
export const productListingDataKey: InjectionKey<Ref<ListingData>> = Symbol('productListingData');
export const activeFacetsKey: InjectionKey<ActiveFacet[]> = Symbol('activeFacets');
export const toggleFacetValueKey: InjectionKey<(facet: FacetHit, value: FacetValue|FacetRangeValue) => void> = Symbol('toggleFacetValue');
export const clearFacetKey: InjectionKey<(facet: FacetHit) => void> = Symbol('clearFacetKey');
export const clearAllFacetsKey: InjectionKey<() => void> = Symbol('clearAllFacets');
export const sortOptionsKey: InjectionKey<string[]> = Symbol('sortOptions');
export const selectedSortOptionKey: InjectionKey<Ref<string>> = Symbol('selectedSort');
export const setSortOptionKey: InjectionKey<(option: string) => void> = Symbol('setSortOption');

export default function () {
  const productBlocks = reactive({});
  const activeFacets = reactive<ActiveFacet[]>([]);

  const navigate = inject(navigateKey, () => Promise.resolve());
  const { route } = useContext();

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const formatPrice = inject('$formatPrice', (p: number) => '');

  let categoryFacetsCacheKey = getCategoryFacetsCacheKey(
    route.pathname,
    route.query
  );

  let categoryProductsCacheKey = getCategoryProductsCacheKey(
    route.pathname,
    route.query
  );  

  const cache = {
    bindCategoryFacets: inject(categoryFacetsCacheKey, () => Promise.resolve()),
    bindCategoryProducts: inject(categoryProductsCacheKey, () =>
      Promise.resolve()
    ),
    subscribe: inject(subscribeKey, () => Promise.resolve()),
    unsubscribe: inject(unsubscribeKey, () => Promise.resolve()),
  };

  //const ga4 = useGA4();

  const categoryFacets = ref<any>(cache.bindCategoryFacets());
  const categoryProducts = ref<any>(cache.bindCategoryProducts());

  const remainingProducts = ref(categoryProducts.value?.products || []);
  const isLoadingProducts = ref(false);
  const selectedSortOption = ref(sortOptions[0]);
  if (route.query.sort) {
    selectedSortOption.value = sortOptions.find(r => r === route.query.sort) || sortOptions[0];
  }

  resetActiveFacets();

  provide(registerListingBlockKey, (uuid, options) => {
    productBlocks[uuid] = options;
    bindProducts();
  });

  provide(unregisterListingBlockKey, (uuid) => {
    delete productBlocks[uuid];
  });

  provide(sortOptionsKey, sortOptions);
  provide(selectedSortOptionKey, readonly(selectedSortOption));
  provide(setSortOptionKey, (option: string) => {
    if (option !== selectedSortOption.value && sortOptions.find(r => r === option)) {
      selectedSortOption.value = option;
      navigate(generateCurrentUrlFromState());
    }
  });

  provide(activeFacetsKey, readonly(activeFacets));
  provide(toggleFacetValueKey, (facet: FacetHit, value: any) => {    
    const activeFacet = activeFacets.find((r) => r.id === facet.id);

    if (facet.type === 'Range') {
      var rangeValue = value as FacetRangeValue;
      const activeValue = activeFacet?.values;

      if (activeValue) {
        activeFacets.splice(activeFacets.findIndex(r => r.id === facet.id), 1)
      }
     
      if (typeof rangeValue.min !== 'undefined' || typeof rangeValue.max !== 'undefined') {
        activeFacets.push({
          id: facet.id,
          name: facet.name,
          type: facet.type,
          values: [{
            value: `[${rangeValue.min}-${rangeValue.max}]`,
            text: `${formatPrice(+rangeValue.min)} - ${formatPrice(+rangeValue.max)}`,
          }],
        });
      }
    }    
    else if (activeFacet?.type === 'Boolean') {
      activeFacets.splice(
        activeFacets.findIndex((r) => r.id === facet.id),
        1
      );
    }
    else if (activeFacet?.values) {
      const activeValue = activeFacet.values.find(
        (r: any) => r.value === value.value
      );
      if (activeValue) {
        activeFacet.values.splice(
          activeFacet?.values.findIndex((r: any) => r.value === value.value),
          1
        );

        if (activeFacet.values.length === 0) {
          activeFacets.splice(
            activeFacets.findIndex((r) => r.id === facet.id),
            1
          );
        }
      } else {
        activeFacet.values.push(value);
      }
    } 
    else {
      activeFacets.push({
        id: facet.id,
        name: facet.name,
        type: facet.type,
        values: [value],
      });
    }

    navigate(generateCurrentUrlFromState()); 
  });
  provide(clearFacetKey, (facet: FacetHit, value: FacetValue) => {
    const activeFacetIdx = activeFacets.findIndex((r) => r.id === facet.id);
    if (activeFacetIdx >= 0) {
      activeFacets.splice(activeFacetIdx, 1);
      navigate(generateCurrentUrlFromState());
    }
  });
  provide(clearAllFacetsKey, (facet: FacetHit, value: FacetValue) => {
    activeFacets.length = 0;
    navigate(generateCurrentUrlFromState());
  });

  onMounted(async () => {
    watch(
      () => route.href,
      async () => {
        isLoadingProducts.value = true;

        if (categoryFacetsCacheKey) {
          cache.unsubscribe(categoryFacetsCacheKey, facetsCacheHandler);
        }
        if (categoryProductsCacheKey) {
          cache.unsubscribe(categoryProductsCacheKey, productsCacheHandler);
        }

        if (route.query.sort) {
          selectedSortOption.value =
            sortOptions.find((r) => r === route.query.sort) || sortOptions[0];
        } else {
          selectedSortOption.value = sortOptions[0];
        }

        categoryFacetsCacheKey = getCategoryFacetsCacheKey(
          route.pathname,
          route.query
        );
        categoryProductsCacheKey = getCategoryProductsCacheKey(
          route.pathname,
          route.query
        );

        cache.subscribe(categoryFacetsCacheKey, facetsCacheHandler, {
          immediate: true,
        });
        cache.subscribe(categoryProductsCacheKey, productsCacheHandler, {
          immediate: true,
        });

        // if (route.query.search) {
        //   ga4.search(route.query.search);
        // }
      },
      { immediate: true }
    );
  });

  provide(allFacetsKey, categoryFacets);
  provide(productListingDataKey, categoryProducts);

  return {
    productBlocks,
    isLoadingProducts,
    context: categoryProducts,
    facets: categoryFacets,
    gridProducts: remainingProducts,
  };

  function generateCurrentUrlFromState() {
    const query: string[] = [];
    if (activeFacets.length >= 0) {
      query.push(`facets=${stringifyFacets()}`);

      function stringifyFacets() {
        return encodeURIComponent(activeFacets.map(stringifyFacet).join(","));
      }
      function stringifyFacet(facet: ActiveFacet) {
        return `${facet.id}:${stringifyValues(facet)}`;
      }
      function stringifyValues(facet: ActiveFacet) {
        return facet.values.map((v: any) => v.value).join("|");
      }
    }
    if (selectedSortOption.value !== sortOptions[0]) {
      query.push(`sort=${selectedSortOption.value}`);
    }
    if (route.query.phrase) {
      query.push(route.query.phrase);
    }
    return route.pathname + (query.length > 0 ? `?${query.join("&")}` : "");
  }

  function productsCacheHandler(val: any) {
    isLoadingProducts.value = false;
    categoryProducts.value = val;
 
    bindProducts();
  }  

  function facetsCacheHandler(val: any) {
    const facets: FacetHit[] = [...(val || [])];
    categoryFacets.value = facets;
    resetActiveFacets();
  } 

  function bindProducts() {
    const products = categoryProducts.value?.products;
    if (!products) {
      remainingProducts.value = [];
      return;
    }

    let i = 0;
    for (let uid in productBlocks) {
      const block = productBlocks[uid];
      const take = parseInt(block.numProducts);
      const blockProducts = products.slice(i, i + take);
      block.setProducts(blockProducts);
      i += take;
    }
    remainingProducts.value = products.slice(i);
  }

  function resetActiveFacets() {
    activeFacets.length = 0;

    if (categoryFacets.value?.length) {
      const facets = categoryFacets.value;
      facets.forEach((facet: any) => {
        const queryFacet = getFacetsFromQuery(route.query).find(
          (f: any) => f.fieldId == facet.id
        );
        const isSelected =
          facet.selectedValues?.length > 0 || queryFacet?.values?.length > 0;
        if (isSelected) {
          activeFacets.push({
            id: facet.id,
            name: facet.name,
            type: facet.type,
            values: getActiveFacetValues(facet, route.query),
          });
        }
      });
    }

    let searchPhrase = route.query?.search?.trim() || "";
    if (searchPhrase) {
      activeFacets.push({
        id: "search",
        name: "Search",
        type: "search",
        values: [
          {
            value: "search",
            label: `"${searchPhrase}"`,
          },
        ],
      } as any);
    }
  }

  function getActiveFacetValues(facet: any, query: any) {
    if (facet.type === "Boolean") {
      return [
        {
          value: facet.id,
          label: facet.name,
        },
      ];
    }
    if (facet.type === "Range") {
      return [
        {
          value: `[${facet.selectedRange.min}-${facet.selectedRange.max}]`,
          label: `${formatPrice(+facet.selectedRange.min)} - ${formatPrice(
            +facet.selectedRange.max
          )}`,
        },
      ];
    }
    const queryFacet = getFacetsFromQuery(query).find(
      (f: any) => f.fieldId == facet.id
    );
    const selectedValues = facet.values.filter(
      (f: any) =>
        facet.selectedValues.find((s: any) => s.value == f.value) ||
        queryFacet.values.find((f: any) => f == facet.id)
    );

    return (
      selectedValues.map((e: any) => {
        return { label: e.label, value: e.value };
      }) || []
    );
  }    
}

export function getFacetsFromQuery(query: any) {
  return query?.facets
    ? decodeURIComponent(query.facets)
        .split(",")
        .map((e) => e.split(":"))
        .reduce((acc: any, cur) => {
          acc.push({
            fieldId: cur[0],
            values: cur[1].split("|"),
          });
          return acc;
        }, [])
    : [];
}

export function getCategoryFacetsCacheKey(
  path: string,
  query: { [key: string]: string }
) {
  return getCurrentCacheKey("facets", path, query);
}

export function getCategoryProductsCacheKey(
  path: string,
  query: { [key: string]: string }
) {
  return getCurrentCacheKey("products", path, query);
}

function getCurrentCacheKey(
  prefix: string,
  path: string,
  query: { [key: string]: string }
) {
  return `${prefix}:${path}${query?.facets ? `&facets=${query?.facets}` : ""}${
    query?.sort ? `&sort=${query.sort}` : ""
  }${query?.search ? `&phrase=${query.search}` : ""}`;
}

export function getContext() {
  return {
    listingData: inject(productListingDataKey, ref<ListingData>(<any>{})),
    allFacets: inject(allFacetsKey, ref<FacetHit[]>(<any>[])),
    activeFacets: inject(activeFacetsKey, []),
    sortOptions: inject(sortOptionsKey, []),
    selectedSortOption: inject(selectedSortOptionKey, ref('')),
    setSortOption: inject(setSortOptionKey, () => null),
    toggleFacetValue: inject(toggleFacetValueKey, () => null),
    getFacetType: (facet: any) => {
      if (facet?.type?.endsWith("Option") || facet?.type === "#Category") {
        return "Checkbox";
      }
      if (facet?.type?.startsWith("#")) {
        return facet.type.replace("#","");
      }
      return facet?.type || "";
    },    
  };
}